import React from "react"
import { Button, Col, Divider, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import SeoHeader from "../components/SeoHeader/SeoHeader"
import RestaurantForm from "../components/Forms/RestaurantForm"
import Wistia from "../components/Wistia/Wistia"
import MediaContext from "../utils/mediaContext"
import Lightbox from "../components/Lightbox/Lightbox"
import {
  clickablePhoneNumber,
  prettifyPhoneNumber,
} from "../utils/phoneNumbers"

const rowProps = {
  align: "bottom",
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    maxWidth: 1360,
  },
}

const titleColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
  style: {
    marginBottom: 24,
    marginTop: 24,
    textAlign: `center`,
  },
}

const copyCol1Props = {
  xs: 22,
  sm: 20,
  md: 8,
  lg: 11,
  xl: 10,
}

const copyCol2Props = {
  xs: 22,
  sm: 20,
  lg: 18,
  xl: 16,
}

const imgColProps = {
  xs: 22,
  sm: 20,
  md: 12,
  lg: 7,
  xl: 6,
  style: {
    padding: 24,
  },
}

const contactRowProps = {
  align: "middle",
  gutter: 32,
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    maxWidth: 1360,
  },
}

const lightboxButtonStyle = {
  background: `none`,
  border: 0,
  cursor: `pointer`,
  outline: `none`,
  padding: 0,
  width: `100%`,
}

const videoWidths = {
  xs: width => (width / 24) * 22,
  sm: width => (width / 24) * 22,
  md: width => (width / 24) * 10,
  lg: width => (width / 24) * 10,
  xl: width => (width / 24) * 10,
  xxl: width => (width / 24) * 10,
}

export default ({ data }) => {
  const { breakpoint, width } = React.useContext(MediaContext)
  const [vis, setVis] = React.useState(false)

  const toggleVis = () => setVis(!vis)

  const { hero, logo, bodyImg, restaurant, grouper, burger } = data

  const [photo, setPhoto] = React.useState(bodyImg.fluid)

  // Reference: https://schema.org/Restaurant
  const jsonld = {
    "@context": "https://schema.org",
    "@type": "Restaurant",
    acceptsReservations: "Yes",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Seneca",
      addressRegion: "SC",
      postalCode: "29678",
      streetAddress: "13500 Clemson Blvd",
    },
    currenciesAccepted: "USD",
    email: "info@traditionsonthelake.com",
    image: hero.localFile.childImageSharp.fluid.src,
    logo: logo.file.url,
    name: "Traditions on the Lake",
    openingHours: ["Su-Th 07:00-22:00", "Fr-Sa 07:00-24:00"],
    paymentAccepted: "Cash, Credit Card",
    priceRange: "$$",
    servesCuisine: ["American", "Fusion"],
    telephone: "(864) 722-2800",
    url: "https://www.lakesidelodgeclemson.com/dining/",
  }

  const meta = {
    seo: {
      title: "Dining | Lakeside Lodge Clemson",
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
      desc: `Located within Clemson's newest luxury hotel and resort, Traditions on the \
        Lake offers travelers and locals classic American cuisine using fresh locally-\
        sourced ingredients.`,
      schema: jsonld,
    },
    uri: "/dining",
  }

  const heroProps = {
    contentfulAsset: hero,
    height: `40vh`,
  }

  const logoProps = {
    src: logo.file.url,
    alt: logo.description,
    style: {
      marginTop: 32,
      maxWidth: 350,
      width: `100%`,
    },
  }

  const bodyImgProps = {
    alt: bodyImg.description,
    fluid: bodyImg.fluid,
    style: {
      borderRadius: 4,
      boxShadow: `0 5px 10px rgba(0,0,0,0.15)`,
    },
  }

  const menuHeaderColProps = {
    xs: 22,
    md: 20,
    lg: 18,
    xl: 16,
  }

  const contactColProps = {
    xs: 22,
    md: 10,
    lg: 9,
    xl: 8,
    style: { textAlign: `center` },
  }

  const formColProps = {
    xs: 22,
    md: 10,
    lg: 9,
    xl: 8,
  }

  const [video, setVideo] = React.useState(null)
  React.useEffect(() => {
    const w = videoWidths[breakpoint](width)
    setVideo(
      <Wistia
        oEmbedUrl={`https://sundoghrv.wistia.com/medias/9947mdbqyz?embedType=iframe&videoFoam=true&videoWidth=${
          w ?? 540
        }`}
        loadingImageAlt={bodyImgProps.alt}
        loadingImageFluid={bodyImgProps.fluid}
      />
    )
  }, [bodyImgProps.alt, bodyImgProps.fluid, breakpoint, setVideo, width])

  return (
    <Shell>
      <Meta {...meta} />
      <Hero {...heroProps} />
      <BookingBar hotelID={2654} />
      <Lightbox
        closeIconColor="rgba(255,255,255,0.8)"
        currentPhoto={photo}
        onCancel={toggleVis}
        photos={[photo]}
        vis={vis}
      />
      <Row {...rowProps}>
        <Col {...titleColProps}>
          <img {...logoProps} alt={logoProps.alt} />
        </Col>
        <Col {...titleColProps}>
          <SeoHeader
            seo={{ level: 1, content: `Traditions on the Lake` }}
            human={{
              level: 2,
              content: `Modern cuisine. Made the old fashioned way.`,
            }}
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...copyCol1Props}>
          <Typography.Paragraph>
            Located within Clemson's newest luxury hotel and resort, Traditions
            on the Lake offers travelers and locals classic American cuisine
            using fresh locally-sourced ingredients. Recipes are inspired by the
            stories of the American melting pot and evoke a unique yet familiar
            dining experience. Legendary Clemson football coach Frank Howard
            believed in giving 110% to the game. We uphold those very same
            time-honored Clemson traditions with every dish we serve.
          </Typography.Paragraph>
        </Col>
        <Col {...imgColProps}>
          <button
            onClick={() => {
              setPhoto(bodyImg.fluid)
              setVis(true)
            }}
            style={lightboxButtonStyle}
          >
            <Img {...bodyImgProps} />
          </button>
        </Col>
        <Col {...copyCol2Props}>
          <Typography.Paragraph>
            That's why on our menu, you'll find free-range, grass-fed,
            antibiotic- and hormone-free meats, organically grown produce, and
            house-made pasta. Because some things are best done the
            old-fashioned way. The grand indoor fireplace and outdoor lakeside
            patio are two perfect places to enjoy a cocktail or order dessert
            with friends and family. The orange-onyx backlit bartop adds to the
            upscale ambiance and invites guests to choose from a wide selection
            of beer, wine, and spirits.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row
        align="middle"
        gutter={[24, 24]}
        justify="center"
        type="flex"
        style={{ margin: `auto`, marginTop: 32, maxWidth: 1360 }}
      >
        <Col xs={22} sm={10} md={9} lg={8}>
          <button
            onClick={() => {
              setPhoto(grouper.fluid)
              setVis(true)
            }}
            style={lightboxButtonStyle}
          >
            <Img
              alt={grouper.description}
              fluid={grouper.fluid}
              style={{ height: 300, ...bodyImgProps.style }}
            />
          </button>
        </Col>
        <Col xs={22} sm={10} md={9} lg={8}>
          <button
            onClick={() => {
              setPhoto(burger.fluid)
              setVis(true)
            }}
            style={lightboxButtonStyle}
          >
            <Img
              alt={burger.description}
              fluid={burger.fluid}
              style={{ height: 300, ...bodyImgProps.style }}
            />
          </button>
        </Col>
      </Row>
      <Row
        {...rowProps}
        style={{
          background: `rgba(0,0,0,0.05)`,
          marginBottom: 32,
          marginTop: 32,
          paddingTop: 20,
          paddingBottom: 20,
          textAlign: `center`,
        }}
      >
        <Col {...menuHeaderColProps}>
          <Button
            size="large"
            type="primary"
            style={{ maxWidth: 325, width: `80%` }}
          >
            <a href="https://www.traditionsonthelake.com/">
              Visit Restaurant Website
            </a>
          </Button>
        </Col>
      </Row>
      <Row
        align="middle"
        justify="center"
        gutter={[36, 24]}
        type="flex"
        style={{ margin: `auto`, marginTop: 20, maxWidth: 1360 }}
      >
        <Col xs={22} md={10} lg={10} xl={10}>
          {video}
        </Col>
        <Col xs={22} md={10} lg={8} xl={6}>
          <SeoHeader
            seo={{ level: 2, content: `Catering` }}
            human={{ level: 3, content: `Meetings & Events.` }}
          />
          <Typography.Paragraph>
            A great event is a whole lot like a great meal: it's fresh, it's
            tasteful, and it happens at just the right time. And good food is
            always a good plan. So whether you're hosting a banquet or a ball, a
            wedding or a work-thing, treat your guests to something delicious
            that they won't find anywhere else, and that they'll be sure to
            remember.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Divider />
      <Row {...rowProps}>
        <Col {...titleColProps}>
          <SeoHeader
            seo={{ level: 2, content: `Contact` }}
            human={{
              level: 4,
              content: `How Can We Serve You Today?`,
            }}
          />
        </Col>
      </Row>
      <Row {...contactRowProps}>
        <Col {...contactColProps}>
          <Typography.Title level={4}>{restaurant.name}</Typography.Title>
          <Typography.Paragraph style={{ margin: `24px auto` }}>
            {restaurant.description.description}
          </Typography.Paragraph>
          <Typography.Paragraph strong>
            {restaurant.streetAddress}
            <br />
            {restaurant.cityStateZip}
          </Typography.Paragraph>
          <Typography.Paragraph>
            For Catering & Events:
            <br />
            <a href={`tel:${clickablePhoneNumber(restaurant.phone)}`}>
              {prettifyPhoneNumber(restaurant.phone)}
            </a>
          </Typography.Paragraph>
        </Col>
        <Col {...formColProps}>
          <RestaurantForm
            formName="Restaurant Contact"
            automationId={40}
            gtmClickId="contact-restaurant-form"
            syncDeal={false}
          />
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "Traditions - Interior" }) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 80, webpQuality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
    logo: contentfulAsset(contentful_id: { eq: "2YsWKGj3CPpBHHrh9cu3VK" }) {
      description
      file {
        url
      }
    }
    bodyImg: contentfulAsset(title: { eq: "Traditions - Blackened Scallops" }) {
      description
      fluid(maxWidth: 500) {
        ...GatsbyContentfulFluid
      }
    }
    grouper: contentfulAsset(
      title: { eq: "Traditions - Potato-Crusted Grouper" }
    ) {
      description
      fluid(maxWidth: 650) {
        ...GatsbyContentfulFluid
      }
    }
    burger: contentfulAsset(title: { eq: "Traditions - All-In Burger" }) {
      description
      fluid(maxWidth: 650) {
        ...GatsbyContentfulFluid
      }
    }
    restaurant: contentfulRestaurant(
      contentful_id: { eq: "1GSKwy2pNHEZxxmgyytMTy" }
    ) {
      name
      streetAddress
      cityStateZip
      phone
      description {
        description
      }
    }
  }
`
